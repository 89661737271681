import { useEffect, useState } from "react";
import GroupwareSideBar from "../../GroupwareSideBar";
import { groupCashflow } from "../../groupwarePath";
import CashEtcItem from "./CashEtcItem";
import CashItem from "./CashItem";
import Top from "./Top";
import TopBanner from "./TopBanner";
import GwAPI from "../../../../API/groupware";
import "./scss/GroupwareCashflow.scss";
import StepBtns from "./StepBtns";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import CashflowStep from "./CashflowStep";

const BalanceIns = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let history = useHistory();
  const step = "step";
  const [today, setToday] = useState("");
  const [cashIdNum, setCashIdNum] = useState(1);
  const [etcIdNum, setEtcIdNum] = useState(1);
  const [generatedReportId, setGeneratedReportId] = useState(
    Number(searchParams.get("report_id"))
  );
  const [recentReportId, setRecentReportId] = useState(0);
  const [mod, setMod] = useState(Number(searchParams.get("mod"))); // 수정페이지
  const [prev, setPrev] = useState(""); // BACK url
  const [next, setNext] = useState(
    `/cashflow/business/bizAct?isIncome=1&type=1&step=2&report_id=${generatedReportId}`
  ); // NEXT url
  const [reportData, setReportData] = useState({
    title: today,
    year: null,
    period: today,
    status: 0,
    update_type: 0,
    update_income: 2,
  });

  const [cashItems, setCashItems] = useState([
    {
      id: `cash_${cashIdNum}`,
      category: {
        category_name: "", // 카테고리명
        type: 0, // 타입없음
        is_income: 2, // 수입, 지출여부 없음
      },
      cash_on: {
        cash_amount: "", // 금액
        cash_currency: "", // 단위
        is_bank: 1, // 은행 있음
        bank_id: "",
        bank_account: "", // 은행계좌
        base_date: today.formattedDate,
        currency_exchange: "", // 예상환전금액
      },
    },
  ]); // cashItem
  const [cashEtcItems, setCashEtcItems] = useState([
    {
      id: `etc_${etcIdNum}`,
      category: {
        category_name: "", // 카테고리명
        type: 0, // 타입없음
        is_income: 2, // 수입, 지출여부 없음
      },
      cash_on: {
        cash_amount: "", // 금액
        cash_currency: "", // 단위
        currency_exchange: "", // 예상환전금액
        bank_id: "",
        bank_account: "", // 은행계좌
        is_bank: 0, // 은행 없음 -> 기타
        base_date: today.formattedDate,
      },
    },
  ]); // cashEtcItem

  useEffect(() => {
    const today = getToday();
    setToday(today.formattedDate);

    if (mod === 1) {
      setCashflowDataInfo(generatedReportId);
    } else {
      setReportData(() => ({
        year: today.year,
        title: today.formattedDate,
        period: today.formattedDate,
        status: 0,
      }));
      resetCash();
      getRecentReportId();
    }
  }, []);

  const resetCash = () => {
    const today = getToday();
    setCashItems(
      cashItems.map((item) => ({
        ...item,
        cash_on: {
          ...item.cash_on,
          base_date: today.formattedDate,
        },
      }))
    );

    setCashEtcItems(
      cashEtcItems.map((item) => ({
        ...item,
        cash_on: {
          ...item.cash_on,
          base_date: today.formattedDate,
        },
      }))
    );
  };

  const addCashItem = () => {
    const today = getToday();
    const newItem = {
      id: `cash_${cashIdNum + 1}`,
      category: {
        category_name: "", // 카테고리명
        type: 0,
        is_income: 2,
      },
      cash_on: {
        cash_amount: "", // 금액
        cash_currency: 0, // 단위
        is_bank: 1,
        bank_id: "",
        bank_account: "", // 은행계좌
        base_date: today.formattedDate,
        currency_exchange: "", // 예상환전금액
      },
    };
    setCashItems([...cashItems, newItem]);
    setCashIdNum((prev) => prev + 1);
  };

  const deleteCashItem = (id) => {
    setCashItems(cashItems.filter((item) => item.id !== id));
  };

  const addCashEtcItem = () => {
    const today = getToday();
    const newItem = {
      id: `etc_${etcIdNum + 1}`,
      category: {
        category_name: "", // 카테고리명
        type: 0,
        is_income: 2,
      },
      cash_on: {
        cash_amount: "", // 금액
        cash_currency: "", // 단위
        currency_exchange: "", // 예상환전금액
        bank_id: "",
        is_bank: 0,
        bank_account: "", // 은행계좌
        base_date: today.formattedDate,
      },
    };
    setCashEtcItems([...cashEtcItems, newItem]);
    setEtcIdNum((prev) => prev + 1);
  };

  const deleteCashEtcItem = (id) => {
    setCashEtcItems(cashEtcItems.filter((item) => item.id !== id));
  };

  const handleUpdateCashItem = (id, cashUpdates, categoryUpdates, part) => {
    if (part === 0) {
      // cash
      setCashItems((prevItems) =>
        prevItems.map((item) =>
          item.id === id
            ? {
                ...item,
                cash_on: { ...item.cash_on, ...cashUpdates },
                category: { ...item.category, ...categoryUpdates },
              }
            : item
        )
      );
    } else {
      // etc
      setCashEtcItems((prevItems) =>
        prevItems.map((item) =>
          item.id === id
            ? {
                ...item,
                cash_on: { ...item.cash_on, ...cashUpdates },
                category: { ...item.category, ...categoryUpdates },
              }
            : item
        )
      );
    }
  };

  const getRecentReportId = () => {
    return GwAPI.getRecentReportIdPlan().then((res) => {
      if (res.status === 200) {
        //console.log("recent id:", res.data.recent_report_id);
        setRecentReportId(res.data.recent_report_id);
        setCashflowDataInfo(res.data.recent_report_id);
      } else {
        alert("최근 데이터를 불러오는데 실패했습니다.");
        return false;
      }
    });
  };

  const createCashflow = (data) => {
    return GwAPI.createCashPlan(data)
      .then((res) => {
        //console.log("res::", res);
        if (res.data?.code === 200 && res.data?.report_id) {
          setGeneratedReportId(res.data.report_id);
          return res.data?.report_id;
        } else {
          alert("레포트 생성을 실패했습니다");
          return false;
        }
      })
      .catch((error) => {
        console.error("Create cashflow failed:", error);
        alert("레포트 생성 중 오류가 발생했습니다");
        return false;
      });
  };

  const updateCashflow = (data) => {
    //console.log("mod data::", data);
    return GwAPI.updateCashPlan(data)
      .then((res) => {
        //console.log("res mod::", res);
        if (res.status === 200) {
          return true;
        } else {
          alert("레포트 수정을 실패했습니다");
          return false;
        }
      })
      .catch((error) => {
        console.error("Update cashflow failed:", error);
        alert("레포트 수정 중 오류가 발생했습니다");
        return false;
      });
  };

  const tempSave = async () => {
    let result = 0;
    //console.log("cashItems:", cashItems);
    const transformedCashItems = cashItems.map((item) => ({
      ...item,
      cash_on: {
        ...item.cash_on,
        cash_amount:
          item.cash_on.cash_amount === ""
            ? 0
            : Number(item.cash_on.cash_amount),
        cash_currency:
          item.cash_on.cash_currency === ""
            ? 0
            : Number(item.cash_on.cash_currency),
        // bank_id: item.cash_on.bank_id === "" ? 0 : Number(item.cash_on.bank_id),
        // bank_account:
        //   item.cash_on.bank_account === ""
        //     ? 0
        //     : Number(item.cash_on.bank_account),
        currency_exchange:
          item.cash_on.currency_exchange === ""
            ? 0
            : Number(item.cash_on.currency_exchange),
      },
    }));

    const transformedCashEtcItems = cashEtcItems.map((item) => ({
      ...item,
      cash_on: {
        ...item.cash_on,
        cash_amount:
          item.cash_on.cash_amount === ""
            ? 0
            : Number(item.cash_on.cash_amount),
        cash_currency:
          item.cash_on.cash_currency === ""
            ? 0
            : Number(item.cash_on.cash_currency),
        // bank_account:
        //   item.cash_on.bank_account === ""
        //     ? 0
        //     : Number(item.cash_on.bank_account),
        currency_exchange:
          item.cash_on.currency_exchange === ""
            ? 0
            : Number(item.cash_on.currency_exchange),
        // bank_id: item.cash_on.bank_id === "" ? 0 : Number(item.cash_on.bank_id),
      },
    }));

    if (generatedReportId) {
      // 생성된 레포트가 있다면 수정
      //console.log("transformedCashItems:", transformedCashItems);
      result = await updateCashflow({
        report: reportData,
        data: [...transformedCashItems, ...transformedCashEtcItems],
      });
    } else {
      // 생성된 레포트가 없다면 추가
      result = await createCashflow({
        report: reportData,
        data: [...transformedCashItems, ...transformedCashEtcItems],
      });
    }
    if (!recentReportId) {
      setRecentReportId(result);
    }
    //console.log("result:", result);
    alert("저장되었습니다.");
    return result;
  };

  const getCashflow = (data) => {
    return GwAPI.getCashPlan(data)
      .then((res) => {
        //console.log("res get::", res);
        if (res.status === 200) {
          return res.data?.CashFlowData;
        } else {
          alert("레포트 가져오기 중 오류가 발생했습니다.");
          return false;
        }
      })
      .catch((error) => {
        console.error("Fetching cashflow failed:", error);
        alert("레포트 가져오기 중 오류가 발생했습니다");
        return false;
      });
  };

  const setCashflowDataInfo = async (id) => {
    const reportData = await getCashflow({
      report_id: id,
      type: 0,
    });
    //console.log("reportData::", reportData);
    if (mod) {
      if (reportData?.report) {
        setReportData(reportData?.report);
      }
    }
    if (reportData?.data.length > 0) {
      let newCashItems = [];
      let newCashEtcItems = [];
      let cashCount = cashIdNum + 1;
      let etcCount = etcIdNum + 1;

      reportData?.data.forEach((data) => {
        const baseDate = new Date(data.cash_on.base_date);
        const formattedBaseDate = baseDate
          .toLocaleDateString("ko-KR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/\. /g, "-")
          .replace(".", "");

        //console.log("formattedBaseDate:", formattedBaseDate);

        if (data.cash_on.is_bank === 1) {
          const updatedCashData = {
            ...data,
            id: `cash_${cashCount++}`,
            cash_on: {
              ...data.cash_on,
              base_date: formattedBaseDate,
            },
          };
          newCashItems.push(updatedCashData);
        } else {
          const updatedEtcData = {
            ...data,
            id: `etc_${etcCount++}`,
            cash_on: {
              ...data.cash_on,
              base_date: formattedBaseDate,
            },
          };
          newCashEtcItems.push(updatedEtcData);
        }
      });
      setCashItems(newCashItems);
      setCashEtcItems(newCashEtcItems);
      setCashIdNum(cashCount);
      setEtcIdNum(etcCount);
    } else {
      resetCash();
    }

    return reportData;
  };

  const goNext = async () => {
    const result = await tempSave();
    if (!result) {
      alert("생성된 레포트가 없습니다.");
    } else {
      if (mod === 1) {
        history.push(
          `/cashflow/business/bizAct?isIncome=1&type=1&step=2&report_id=${generatedReportId}&mod=1`
        );
      } else {
        history.push(
          `/cashflow/business/bizAct?isIncome=1&type=1&step=2&report_id=${result}&recent_id=${
            recentReportId ? recentReportId : result
          }`
        );
      }
    }
  };

  const getToday = () => {
    const today = new Date();

    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    const formattedDate = `${year}-${month}-${day}`;

    setReportData((prevState) => ({
      ...prevState,
      year: year,
    }));

    return { formattedDate, year, month, day };
  };

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupCashflow} />
      <div className="commute_wrapper">
        <div id="GroupwareCashflow">
          <TopBanner />
          <Top page={step} />
          <CashflowStep step={1} />
          <div className="step_cont">
            <div className="wrap bg_wh rounded">
              <div className="tit_box">
                <h4 className="blue">STEP 1. 보유현금 입력</h4>
                <button className="temp_save blue_bg" onClick={tempSave}>
                  임시 저장하기
                </button>
              </div>
              <strong className="desc">
                즉시 인출가능한 금액을 입력해보세요.<span>단위: 천원</span>
              </strong>
              {/* <div className="get_balance rounded">
                <div>
                  <strong>기준일자의 전일 잔고로 조회 & 작성해보세요.</strong>
                  <span>예&#41; 23.2.9기준 → 23.2.8. 23:59 잔고</span>
                </div>
                 <a href="#" className="get_btn">
                  기업자금 불러오기
                </a> 
              </div> */}
              {/* <div className="total">
                <strong>
                  2023년 12월 9일 기준
                  <span className="blue">가용잔고 200억 5000천</span> 만원
                  입니다.
                </strong>
                <a href="#" className="detail_btn blue_bg rounded">
                  자세하게 쓰기
                </a>
              </div> */}
              <div className="add_money">
                <table>
                  <colgroup>
                    <col width="190px" />
                    <col />
                    <col width="220px" />
                    <col width="90px" />
                    <col width="340px" />
                    <col width="42px" />
                  </colgroup>

                  {cashItems.map((item) => (
                    <CashItem
                      key={item.id}
                      id={item.id}
                      category_name={item.category.category_name}
                      type={item.category.type}
                      is_income={item.category.is_income}
                      bank_id={item.cash_on.bank_id}
                      bank_account={item.cash_on.bank_account}
                      cash_amount={item.cash_on.cash_amount}
                      cash_currency={item.cash_on.cash_currency}
                      currency_exchange={item.cash_on.currency_exchange}
                      base_date={item.cash_on.base_date}
                      deleteCashItem={deleteCashItem}
                      handleUpdateCashItem={handleUpdateCashItem}
                    />
                  ))}
                  <tr>
                    <td colSpan={6} className="add_box">
                      <button className="add" onClick={addCashItem}>
                        +
                      </button>
                    </td>
                  </tr>

                  {cashEtcItems.map((item) => (
                    <CashEtcItem
                      key={item.id}
                      id={item.id}
                      category_name={item.category.category_name}
                      type={item.category.type}
                      is_income={item.category.is_income}
                      cash_amount={item.cash_on.cash_amount}
                      cash_currency={item.cash_on.cash_currency}
                      currency_exchange={item.cash_on.currency_exchange}
                      base_date={item.cash_on.base_date}
                      deleteCashEtcItem={deleteCashEtcItem}
                      handleUpdateCashItem={handleUpdateCashItem}
                    />
                  ))}
                  <tr>
                    <td colSpan={6} className="add_box">
                      <button className="add" onClick={addCashEtcItem}>
                        +
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <StepBtns prev={prev} next={next} goNext={goNext} />
        </div>
      </div>
    </div>
  );
};

export default BalanceIns;

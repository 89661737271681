import react, { useEffect, useState } from "react";
import SideBar from "../../SideBar";
import "./css/RndPlan.scss";
import "./css/RndPlanFirst.scss";
import RnDText from "../RnDText";
import FirstInfoText from "../../FirstInfoText";
import questionMark from "../../../../assets/images/businessPlan/icon-information-question-mark.svg";
import BringingUpIcon from "../../BringingUpIcon";
import buttonIcon from "../../../../assets/images/businessPlan/button-add-col.svg";
import prevButton from "../../../../assets/images/businessPlan/button-prev.svg";
import nextButton from "../../../../assets/images/businessPlan/button-next.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import CommonAPI from "../../../../API/common";
import _ from "lodash";
import InfoText from "../../InfoText";
import PlanInfo from "../../PlanInfo";
import { useRecoilValue } from "recoil";
import { businessPlanDefaultData } from "../../../../atom";
import SidebarRnd from "../../SidebarRnd";

const RnDPlanFirst = () => {
  useEffect(() => {
    CommonAPI.getBusinessPlanList({ category1_idx: 1, category2_idx: 11 })
      .then((res) => {
        if (res.data.items.length > 0) {
          window.localStorage.setItem("itemIdx", res.data.items[0].idx);
        }
      })
      .then(() => {
        if (window.localStorage.getItem("itemIdx") !== null) {
          CommonAPI.getBusinessData(
            window.localStorage.getItem("itemIdx")
          ).then((res) => {
            getDefaultData(res.data);
            setResponseData(res.data);
          });
        }
      });
  }, []);
  let saveSelect = "";
  const history = useHistory();
  const defaultData = useRecoilValue(businessPlanDefaultData);
  const [choice, setChoice] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [pageOneData, setPageOneData] = useState({
    choicePlan: "1",
    choiceSecure: "1",
    choiceSelect: "1",
    choiceContest: "1",
    rnd_plan: ["1", "1", "1", "1", "1", "1", "1"],
    standard_classification: ["1", "1", "1"],
    technology_classification: ["1", "1", "1"],
    general_rnd_name: ["1", "1"],
    task_rnd_name: ["1", "1"],
    development_agency: ["1", "1", "1", "1"],
    research_director: ["1", "1", "1", "1", "1", "1"],
    practical_person: ["1", "1", "1", "1", "1", "1"],
  });
  const [periodTableData, setPeriodTableData] = useState([]);
  const [periodHeadData, setPeriodHeadData] = useState({
    startDate: 1665759600000,
    endDate: 10,
    year: 1,
    month: 1,
  });
  const [periodRowsData, setPeriodRowsData] = useState([
    {
      step: "1",
      theYear: "1",
      startDate: 10,
      endDate: 10,
      year: 1,
      month: 1,
      placeholder: ["1", "1"],
    },
    {
      step: "1",
      theYear: "1",
      startDate: 10,
      endDate: 10,
      year: 1,
      month: 1,
      placeholder: ["1", "N"],
    },
    {
      step: "1",
      theYear: "1",
      startDate: 10,
      endDate: 10,
      year: 1,
      month: 1,
      placeholder: ["N", "1"],
    },
    {
      step: "1",
      theYear: "1",
      startDate: 10,
      endDate: 10,
      year: 1,
      month: 1,
      placeholder: ["N", "N"],
    },
  ]);
  const [costTableData, setCostTableData] = useState([]);
  const [costHeadData, setCostHeadData] = useState({
    gov_cash: "10",
    inst_cash: "10",
    inst_stocks: "10",
    local_cash: "10",
    local_stocks: "10",
    etc_cash: "10",
    etc_stocks: "10",
    sum_cash: "10",
    sum_stocks: "10",
    sum_sum: "10",
    extraCost: "10",
  });
  const [costRowsData, setCostRowsData] = useState([
    {
      step: "1",
      theYear: "1",
      gov_cash: 10,
      inst_cash: 10,
      inst_stocks: 10,
      local_cash: 10,
      local_stocks: 10,
      etc_cash: 10,
      etc_stocks: 10,
      sum_cash: 10,
      sum_stocks: 10,
      sum_sum: 10,
      extraCost: 10,
      placeholder: ["1", "1"],
    },
    {
      step: "1",
      theYear: "1",
      gov_cash: 10,
      inst_cash: 10,
      inst_stocks: 10,
      local_cash: 10,
      local_stocks: 10,
      etc_cash: 10,
      etc_stocks: 10,
      sum_cash: 10,
      sum_stocks: 10,
      sum_sum: 10,
      extraCost: 10,
      placeholder: ["1", "N"],
    },
    {
      step: "1",
      theYear: "1",
      gov_cash: 10,
      inst_cash: 10,
      inst_stocks: 10,
      local_cash: 10,
      local_stocks: 10,
      etc_cash: 10,
      etc_stocks: 10,
      sum_cash: 10,
      sum_stocks: 10,
      sum_sum: 10,
      extraCost: 10,
      placeholder: ["N", "1"],
    },
    {
      step: "1",
      theYear: "1",
      gov_cash: 10,
      inst_cash: 10,
      inst_stocks: 10,
      local_cash: 10,
      local_stocks: 10,
      etc_cash: 10,
      etc_stocks: 10,
      sum_cash: 10,
      sum_stocks: 10,
      sum_sum: 10,
      extraCost: 10,
      placeholder: ["N", "N"],
    },
  ]);

  const [agencyRowsData, setAgencyRowsData] = useState([
    {
      select_option: "1",
      organization_name: "1",
      manager: "1",
      position: "1",
      phone_number: "1",
      email: "1",
      role: "1",
      type: "1",
    },
    {
      select_option: "1",
      organization_name: "1",
      manager: "1",
      position: "1",
      phone_number: "1",
      email: "1",
      role: "1",
      type: "1",
    },
    {
      select_option: "1",
      organization_name: "1",
      manager: "1",
      position: "1",
      phone_number: "1",
      email: "1",
      role: "1",
      type: "1",
    },
  ]);

  const changeSelectData = (data) => {
    if (data === "||&병합&||") {
      data = saveSelect;
    }

    if (data === "공동연구개발기관") {
      saveSelect = "공동연구개발기관";
      return "1";
    } else if (data === "위탁연구개발기관") {
      saveSelect = "위탁연구개발기관";
      return "2";
    } else if (data === "연구개발기관 외 기관") {
      saveSelect = "연구개발기관 외 기관";
      return "3";
    }
  };

  const getDateTimeStamp = (date) => {
    const [year, month, day] = date.split(".");
    const timeStamp = new Date(+year, month - 1, +day);

    return timeStamp.getTime();
  };
  const getDefaultData = (data) => {
    let selectArray = [];
    let dateArray = [];
    data.content.forEach((item, index) => {
      if (item.subject === "연구개발계획서") {
        dateArray.push(
          getDateTimeStamp(item.content[18][6].content.substring(0, 10)),
          getDateTimeStamp(item.content[18][6].content.substring(13, 23)),
          getDateTimeStamp(item.content[19][6].content.substring(0, 10)),
          getDateTimeStamp(item.content[19][6].content.substring(13, 23)),
          getDateTimeStamp(item.content[20][6].content.substring(0, 10)),
          getDateTimeStamp(item.content[20][6].content.substring(13, 23)),
          getDateTimeStamp(item.content[21][6].content.substring(0, 10)),
          getDateTimeStamp(item.content[21][6].content.substring(13, 23)),
          getDateTimeStamp(item.content[22][6].content.substring(0, 10)),
          getDateTimeStamp(item.content[22][6].content.substring(13, 23))
        );
        selectArray.push(
          changeSelectData(item.content[33][0].content),
          changeSelectData(item.content[34][0].content),
          changeSelectData(item.content[35][0].content)
        );
        let content = {
          choicePlan: "",
          choiceSecure: "",
          choiceSelect: "",
          choiceContent: "",
          rnd_plan: [
            item.content[2][3].content,
            item.content[2][11].content,
            item.content[3][3].content,
            item.content[3][11].content,
            item.content[4][3].content,
            item.content[4][11].content,
            item.content[5][11].content,
          ],
          standard_classification: [
            item.content[7][6].content,
            item.content[7][9].content,
            item.content[7][12].content,
          ],
          technology_classification: [
            item.content[8][6].content,
            item.content[8][9].content,
            item.content[8][12].content,
          ],
          general_rnd_name: [
            item.content[9][6].content,
            item.content[10][6].content,
          ],
          task_rnd_name: [
            item.content[11][6].content,
            item.content[12][6].content,
          ],
          development_agency: [
            item.content[13][6].content,
            item.content[13][12].content,
            item.content[14][6].content,
            item.content[14][12].content,
          ],
          research_director: [
            item.content[15][6].content,
            item.content[15][12].content,
            item.content[16][6].content,
            item.content[16][12].content,
            item.content[17][6].content,
            item.content[17][12].content,
          ],
          practical_person: [
            item.content[36][6].content,
            item.content[36][11].content,
            item.content[37][6].content,
            item.content[37][11].content,
            item.content[38][6].content,
            item.content[38][11].content,
          ],
        };
        setPageOneData(content);
        content = {
          startDate: dateArray[0],
          endDate: dateArray[1],
          year: item.content[18][6].content.substring(24, 25),
          month: item.content[18][6].content.substring(27, 28),
        };
        setPeriodHeadData(content);
        content = [
          {
            step: item.content[19][3].content.substring(0, 1),
            theYear: item.content[19][5].content.substring(0, 1),
            startDate: dateArray[2],
            endDate: dateArray[3],
            year: item.content[19][6].content.substring(24, 25),
            month: item.content[19][6].content.substring(27, 28),
            placeholder: ["1", "1"],
          },
          {
            step: item.content[19][3].content.substring(0, 1),
            theYear: item.content[20][5].content.substring(0, 1),
            startDate: dateArray[4],
            endDate: dateArray[5],
            year: item.content[20][6].content.substring(24, 25),
            month: item.content[20][6].content.substring(27, 28),
            placeholder: ["1", "N"],
          },
          {
            step: item.content[21][3].content.substring(0, 1),
            theYear: item.content[21][5].content.substring(0, 1),
            startDate: dateArray[6],
            endDate: dateArray[7],
            year: item.content[21][6].content.substring(24, 25),
            month: item.content[21][6].content.substring(27, 28),
            placeholder: ["N", "1"],
          },
          {
            step: item.content[21][3].content.substring(0, 1),
            theYear: item.content[22][5].content.substring(0, 1),
            startDate: dateArray[8],
            endDate: dateArray[9],
            year: item.content[22][6].content.substring(24, 25),
            month: item.content[22][6].content.substring(27, 28),
            placeholder: ["N", "N"],
          },
        ];
        setPeriodRowsData(content);
        content = {
          gov_cash: item.content[26][2].content,
          inst_cash: item.content[26][3].content,
          inst_stocks: item.content[26][4].content,
          local_cash: item.content[26][5].content,
          local_stocks: item.content[26][6].content,
          etc_cash: item.content[26][7].content,
          etc_stocks: item.content[26][8].content,
          sum_cash: item.content[26][9].content,
          sum_stocks: item.content[26][10].content,
          sum_sum: item.content[26][11].content,
          extraCost: item.content[26][12].content,
        };
        setCostHeadData(content);
        content = [
          {
            step: item.content[27][0].content.substring(0, 1),
            theYear: item.content[27][1].content.substring(0, 1),
            gov_cash: item.content[27][2].content,
            inst_cash: item.content[27][3].content,
            inst_stocks: item.content[27][4].content,
            local_cash: item.content[27][5].content,
            local_stocks: item.content[27][6].content,
            etc_cash: item.content[27][7].content,
            etc_stocks: item.content[27][8].content,
            sum_cash: item.content[27][9].content,
            sum_stocks: item.content[27][10].content,
            sum_sum: item.content[27][11].content,
            extraCost: item.content[27][12].content,
            placeholder: ["1", "1"],
          },
          {
            step: item.content[27][0].content.substring(0, 1),
            theYear: item.content[28][1].content.substring(0, 1),
            gov_cash: item.content[28][2].content,
            inst_cash: item.content[28][3].content,
            inst_stocks: item.content[28][4].content,
            local_cash: item.content[28][5].content,
            local_stocks: item.content[28][6].content,
            etc_cash: item.content[28][7].content,
            etc_stocks: item.content[28][8].content,
            sum_cash: item.content[28][9].content,
            sum_stocks: item.content[28][10].content,
            sum_sum: item.content[28][11].content,
            extraCost: item.content[28][12].content,
            placeholder: ["1", "N"],
          },
          {
            step: item.content[29][0].content.substring(0, 1),
            theYear: item.content[29][1].content.substring(0, 1),
            gov_cash: item.content[29][2].content,
            inst_cash: item.content[29][3].content,
            inst_stocks: item.content[29][4].content,
            local_cash: item.content[29][5].content,
            local_stocks: item.content[29][6].content,
            etc_cash: item.content[29][7].content,
            etc_stocks: item.content[29][8].content,
            sum_cash: item.content[29][9].content,
            sum_stocks: item.content[29][10].content,
            sum_sum: item.content[29][11].content,
            extraCost: item.content[29][12].content,
            placeholder: ["N", "1"],
          },
          {
            step: item.content[29][0].content.substring(0, 1),
            theYear: item.content[30][1].content.substring(0, 1),
            gov_cash: item.content[30][2].content,
            inst_cash: item.content[30][3].content,
            inst_stocks: item.content[30][4].content,
            local_cash: item.content[30][5].content,
            local_stocks: item.content[30][6].content,
            etc_cash: item.content[30][7].content,
            etc_stocks: item.content[30][8].content,
            sum_cash: item.content[30][9].content,
            sum_stocks: item.content[30][10].content,
            sum_sum: item.content[30][11].content,
            extraCost: item.content[30][12].content,
            placeholder: ["N", "N"],
          },
        ];
        setCostRowsData(content);
        content = [
          {
            select_option: selectArray[0],
            organization_name: item.content[33][3].content,
            manager: item.content[33][5].content,
            position: item.content[33][6].content,
            phone_number: item.content[33][8].content,
            email: item.content[33][9].content,
            role: item.content[33][11].content,
            type: item.content[33][12].content,
          },
          {
            select_option: selectArray[1],
            organization_name: item.content[34][3].content,
            manager: item.content[34][5].content,
            position: item.content[34][6].content,
            phone_number: item.content[34][8].content,
            email: item.content[34][9].content,
            role: item.content[34][11].content,
            type: item.content[34][12].content,
          },
          {
            select_option: selectArray[2],
            organization_name: item.content[35][3].content,
            manager: item.content[35][5].content,
            position: item.content[35][6].content,
            phone_number: item.content[35][8].content,
            email: item.content[35][9].content,
            role: item.content[35][11].content,
            type: item.content[35][12].content,
          },
        ];
        setAgencyRowsData(content);
      }
    });
  };
  const calDate = (startDate, endDate, index) => {
    const rowsInput = [...periodRowsData];
    let yearData = endDate - startDate;
    let date = new Date(yearData);
    rowsInput[index].year = date.getFullYear() - 1970;
    rowsInput[index].month = date.getMonth();
    setPeriodRowsData(rowsInput);
  };

  const handleChange = (e, idx) => {
    const { name, value } = e.target;
    pageOneData[name][idx] = value;
    setPageOneData(() => ({
      ...pageOneData,
    }));
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setPageOneData(() => ({
      ...pageOneData,
      [name]: value,
    }));
  };
  const handlePeriodChange = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...periodRowsData];
    rowsInput[index][name] = value;
    setPeriodRowsData(rowsInput);
  };

  const handleCostChange = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...costRowsData];
    rowsInput[index][name] = value;
    setCostRowsData(rowsInput);
  };

  const handleDateChange = (index, date, type) => {
    const rowsInput = [...periodRowsData];
    if (type === 0) {
      rowsInput[index].startDate = date;
    } else if (type === 1) {
      rowsInput[index].endDate = date;
    }
    if (
      rowsInput[index].startDate > rowsInput[index].endDate &&
      rowsInput[index].endDate !== null
    ) {
      alert("종료날짜가 시작날짜보다 빠릅니다.");
      return;
    }
    setPeriodRowsData(rowsInput);
    if (
      rowsInput[index].startDate !== null &&
      rowsInput[index].endDate !== null
    ) {
      calDate(rowsInput[index].startDate, rowsInput[index].endDate, index);
    }
  };

  const handleAgencyChange = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...agencyRowsData];
    rowsInput[index][name] = value;
    setAgencyRowsData(rowsInput);
  };

  const deleteTableRows = (index) => {
    const periodRows = [...periodRowsData];
    const costRows = [...costRowsData];
    periodRows.splice(index, 1);
    costRows.splice(index, 1);
    setPeriodRowsData(periodRows);
    setCostRowsData(costRows);
  };

  const deleteAgencyTableRows = (index) => {
    const rows = [...agencyRowsData];
    rows.splice(index, 1);
    setAgencyRowsData(rows);
  };

  const addTableRows = () => {
    const periodRowsInput = {
      step: "",
      theYear: "",
      startDate: null,
      endDate: null,
      year: undefined,
      month: undefined,
      placeholder: ["", ""],
    };
    const costRowsInput = {
      step: "",
      theYear: "",
      gov_cash: null,
      inst_cash: null,
      inst_stocks: null,
      local_cash: null,
      local_stocks: null,
      etc_cash: null,
      etc_stocks: null,
      sum_cash: null,
      sum_stocks: null,
      sum_sum: null,
      extraCost: null,
      placeholder: ["", ""],
    };
    setPeriodRowsData([...periodRowsData, periodRowsInput]);
    setCostRowsData([...costRowsData, costRowsInput]);
  };

  const addAgencyTableRows = () => {
    const rowsInput = {
      select_option: "",
      organization_name: "",
      manager: "",
      position: "",
      phone_number: "",
      email: "",
      role: "",
      type: "",
    };
    setAgencyRowsData([...agencyRowsData, rowsInput]);
  };

  const sendData = () => {
    let periodData = [];
    let costData = [];
    let agencyData = [];
    let periodPrevStepCheck = periodRowsData[0].step;
    let costPrevStepCheck = costRowsData[0].step;

    periodRowsData.map((el, index) => {
      let check = false;
      if (index !== 0) {
        if (periodPrevStepCheck !== el.step) {
          periodPrevStepCheck = el.step;
          check = false;
        } else {
          check = true;
        }
      }
      let data = [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        {
          type: "text",
          content: index === 0 ? "단계" : "||&병합&||",
          option: {
            background: true,
            color: "#E6E6E6",
            border: { type: 0 },
          },
        },
        {
          type: "text",
          content: check ? "||&병합&||" : `${el.step}단계`,
          option: {
            background: true,
            color: "#E6E6E6",
            border: { type: 0 },
          },
        },
        { type: "text", content: check ? "||&병합&||" : "=&병합&=" },
        {
          type: "text",
          content: `${el.theYear}년차`,
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: `${window.$Global.convertDateDot(
            el.startDate
          )} - ${window.$Global.convertDateDot(el.endDate)}(${el.year}년 ${
            el.month
          }개월)`,
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
      ];
      periodData.push(data);
    });

    costRowsData.map((el, index) => {
      let check = false;
      if (index !== 0) {
        if (costPrevStepCheck !== el.step) {
          costPrevStepCheck = el.step;
          check = false;
        } else {
          check = true;
        }
      }
      let data = [
        {
          type: "text",
          content: check ? "||&병합&||" : `${el.step}단계`,
          option: {
            background: true,
            color: "#E6E6E6",
            option: { border: { type: 0 } },
          },
        },
        {
          type: "text",
          content: `${el.theYear}년차`,
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: el.gov_cash, option: { border: { type: 0 } } },
        {
          type: "text",
          content: el.inst_cash,
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: el.inst_stocks,
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: el.local_cash,
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: el.local_stocks,
          option: { border: { type: 0 } },
        },
        { type: "text", content: el.etc_cash, option: { border: { type: 0 } } },
        {
          type: "text",
          content: el.etc_stocks,
          option: { border: { type: 0 } },
        },
        { type: "text", content: el.sum_cash, option: { border: { type: 0 } } },
        {
          type: "text",
          content: el.sum_stocks,
          option: { border: { type: 0 } },
        },
        { type: "text", content: el.sum_sum, option: { border: { type: 0 } } },
        {
          type: "text",
          content: el.extraCost,
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
      ];
      costData.push(data);
    });

    agencyRowsData.sort((a, b) => {
      if (a.select_option > b.select_option) return 1;
      if (a.select_option === b.select_option) return 0;
      if (a.select_option < b.select_option) return -1;
    });

    let agencyPrevStepCheck = agencyRowsData[0].select_option;

    agencyRowsData.map((el, index) => {
      let check = false;
      if (index !== 0) {
        if (agencyPrevStepCheck !== el.select_option) {
          agencyPrevStepCheck = el.select_option;
          check = false;
        } else {
          check = true;
        }
      }
      if (el.select_option === "1") {
        el.select_option = "공동연구개발기관";
      } else if (el.select_option === "2") {
        el.select_option = "위탁연구개발기관";
      } else if (el.select_option === "3") {
        el.select_option = "연구개발기관 외 기관";
      }

      let data = [
        {
          type: "text",
          content: check ? "||&병합&||" : el.select_option,
          option: {
            background: true,
            color: "#E6E6E6",
            border: { type: 0 },
          },
        },
        { type: "text", content: check ? "||&병합&||" : "=&병합&=" },
        { type: "text", content: check ? "||&병합&||" : "=&병합&=" },
        {
          type: "text",
          content: el.organization_name,
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: el.manager, option: { border: { type: 0 } } },
        { type: "text", content: el.position, option: { border: { type: 0 } } },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: el.phone_number,
          option: { border: { type: 0 } },
        },
        { type: "text", content: el.email, option: { border: { type: 0 } } },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: el.role, option: { border: { type: 0 } } },
        {
          type: "text",
          content: el.type,
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
      ];
      agencyData.push(data);
    });

    const contents = [
      [
        // OK
        {
          type: "text",
          content: "연구개발계획서",
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: `[${
            pageOneData.choicePlan === "forApp" ? "√" : " "
          }] 신청용\n[${
            pageOneData.choicePlan === "forConvention" ? "√" : " "
          }] 협약용`,
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "보안등급",
          option: { border_top: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
      ],
      [
        // OK
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        {
          type: "text",
          content: `일반[${
            pageOneData.choiceSecure === "general" ? "√" : " "
          }],보안[${pageOneData.choiceSecure === "security" ? "√" : " "}]`,
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
      ],
      [
        {
          type: "text",
          content: "중앙행정기관명",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.rnd_plan[0],
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "사업명",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "사업명",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.rnd_plan[1],
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
      ],
      [
        {
          type: "text",
          content: "전문기관명(해당 시 작성)",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.rnd_plan[2],
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "||&병합&||" },
        {
          type: "text",
          content: "내역 사업명(해당 시 작성)",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.rnd_plan[3],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
      ],
      [
        {
          type: "text",
          content: "공고번호",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.rnd_plan[4],
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "총괄연구개발 식별번호(해당 시 작성)",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.rnd_plan[5],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
      ],
      [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        {
          type: "text",
          content: "연구개발과제번호",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.rnd_plan[6],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
      ],
      [
        {
          type: "text",
          content: "선정방식",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: `정책지정[${choice ? "√" : " "}]\n공모: 지정공모[${
            pageOneData.choiceContest === "designate" ? "√" : " "
          }], 품목공모[${
            pageOneData.choiceContest === "item" ? "√" : " "
          }], 분야공모[${
            pageOneData.choiceContest === "field" ? "√" : " "
          }], 자유공모[${pageOneData.choiceContest === "free" ? "√" : " "}]`,
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
            border_left: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
      ],
      [
        {
          type: "text",
          content: "기술분류",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "국가과학기술표준분류",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "1순위 소분류 코드명",
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.standard_classification[0],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "2순위 소분류 코드명",
          option: { border: { type: 0 } },
        },

        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.standard_classification[1],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "3순위 소분류 코드명",
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.standard_classification[2],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
      ],
      [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        {
          type: "text",
          content: "부처기술분류(해당 시 작업)",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "1순위 소분류 코드명",
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.technology_classification[0],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "2순위 소분류 코드명",
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.technology_classification[1],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "3순위 소분류 코드명",
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.technology_classification[2],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
      ],
      [
        {
          type: "text",
          content: "총괄연구개발명(해당 시 작성)",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "국문",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.general_rnd_name[0],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
      ],
      [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        {
          type: "text",
          content: "영문",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.general_rnd_name[1],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
      ],
      [
        {
          type: "text",
          content: "연구개발과제명",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "국문",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.task_rnd_name[0],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
      ],
      [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        {
          type: "text",
          content: "영문",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.task_rnd_name[1],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
      ],
      [
        {
          type: "text",
          content: "주관연구개발기관",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "기관명",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.development_agency[0],
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "사업자등록번호",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.development_agency[1],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
      ],
      [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        {
          type: "text",
          content: "주소",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: pageOneData.development_agency[2] },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "법인등록번호",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: pageOneData.development_agency[3] },
      ],
      [
        {
          type: "text",
          content: "연구책임자",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "성명",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.research_director[0],
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "직위",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.research_director[1],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
      ],
      [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        {
          type: "text",
          content: "연락처",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "직장전화",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: pageOneData.research_director[2],
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "휴대전화",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.research_director[3],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
      ],
      [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        {
          type: "text",
          content: "전자우편",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: pageOneData.research_director[4],
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "국가연구자번호",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.research_director[5],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
      ],
      [
        // OK
        {
          type: "text",
          content: "연구개발기간",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "전체",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: `${window.$Global.convertDateDot(
            periodHeadData.startDate
          )} - ${window.$Global.convertDateDot(periodHeadData.endDate)}(${
            periodHeadData.year
          }년 ${periodHeadData.month}개월)`,
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
      ],
    ];
    for (const p of periodData) {
      contents.push(p);
    }
    contents.push(
      [
        {
          type: "text",
          content: "연구개발비(단위: 천원)",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "정부지원연구개발비",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "기관부담연구개발비",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "그 외 기관 등의 지원금",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "합계",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "연구개발비 외 지원금",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
      ],
      [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        {
          type: "text",
          content: "지방자치단체",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "기타( )",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
      ],
      [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        {
          type: "text",
          content: "현금",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "현금",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "현물",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "현금",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "현물",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "현금",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "현물",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "현금",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "현물",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "합계",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "||&병합&||" },
      ],
      [
        {
          type: "text",
          content: "총계",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: costHeadData.gov_cash,
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: costHeadData.inst_cash,
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: costHeadData.inst_stocks,
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: costHeadData.local_cash,
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: costHeadData.local_stocks,
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: costHeadData.etc_cash,
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: costHeadData.etc_stocks,
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: costHeadData.sum_cash,
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: costHeadData.sum_stocks,
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: costHeadData.sum_sum,
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: costHeadData.extraCost,
          option: { border: { type: 0 } },
        },
      ]
    );
    for (const c of costData) {
      contents.push(c);
    }
    contents.push(
      [
        {
          type: "text",
          content: "공동연구개발기관 등 (해당 시 작성)",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "기관명",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "책임자",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "직위",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "휴대전화",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "전자우편",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "비고",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
      ],
      [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        {
          type: "text",
          content: "역할",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "기관유형",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
      ]
    );
    for (const aData of agencyData) {
      contents.push(aData);
    }
    contents.push(
      [
        {
          type: "text",
          content: "연구개발과제실무담당자",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "성명",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.practical_person[0],
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "직위",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.practical_person[1],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
      ],
      [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        {
          type: "text",
          content: "연락처",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "직장전화",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: pageOneData.practical_person[2],
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "휴대전화",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.practical_person[3],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
      ],
      [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        {
          type: "text",
          content: "전자우편",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: pageOneData.practical_person[4],
          option: { border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: "국가연구자번호",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        { type: "text", content: "=&병합&=" },
        { type: "text", content: "=&병합&=" },
        {
          type: "text",
          content: pageOneData.practical_person[5],
          option: {
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        { type: "text", content: "=&병합&=" },
      ]
    );
    const contentData = {
      subject: "연구개발계획서",
      type: "table",
      content: [...contents],
    };
    sendDataToAPI(contentData);
  };

  const sendDataToAPI = (contentData) => {
    responseData.content = _.uniqBy(responseData.content, "subject");
    responseData.content.forEach((parentItem, index) => {
      if (parentItem.subject === contentData.subject) {
        responseData.content[index].content = contentData.content;
        setResponseData({ ...responseData });
      }
    });

    if (window.localStorage.getItem("itemIdx") !== null) {
      const payload = {
        title: "R&D 사업계획서",
        content: [...responseData.content],
      };
      CommonAPI.updateBusinessData(
        window.localStorage.getItem("itemIdx"),
        payload
      ).then(() => history.push("second"));
    } else {
      const payload = {
        title: "R&D 사업계획서",
        category1_idx: 1,
        category2_idx: 11,
        content: [contentData],
      };
      CommonAPI.addBusinessData(payload).then((res) => {
        window.localStorage.setItem("itemIdx", res.data.item_idx);
        history.push("second");
      });
    }

    // const content12 = [
    //   {
    //     subject: "연구개발과제의 필요성",
    //     type: "multiple",
    //     content: [
    //       {
    //         type: "text",
    //         content: "111",
    //       },
    //       {
    //         type: "table",
    //         content: [
    //           [
    //             {
    //               type: "text",
    //               content: "222",
    //             },
    //           ],
    //           [
    //             {
    //               type: "text",
    //               content: "333",
    //             },
    //           ],
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     subject: "연구개발과제의 목표 및 내용",
    //     type: "multiple",
    //     content: [
    //       {
    //         type: "text",
    //         content: "111",
    //       },
    //       {
    //         type: "table",
    //         content: [
    //           [
    //             {
    //               type: "text",
    //               content: "222",
    //             },
    //           ],
    //           [
    //             {
    //               type: "text",
    //               content: "333",
    //             },
    //           ],
    //         ],
    //       },
    //     ],
    //   },
    // ];
    // window.$Global
    //   .fileDownloadSync("test/hwp", {
    //     data: {
    //       idx: 11,
    //       content: content12,
    //     },
    //   })
    //   .then((r) => console.log(r));
  };

  return (
    <div className="wrapper">
      <SidebarRnd />
      <div className="rnd_wrapper">
        <RnDText index={1} />
        <div className="rnd_plan">
          <div className="plan_one_wrapper">
            <div className="plan_text">
              <p>연구개발 계획서</p>
              <FirstInfoText idx={0} />
              <div className="plan_btn_wrapper">
                <div className="plan_radio_btn">
                  <input
                    type="radio"
                    name="choicePlan"
                    id="forApp"
                    value="forApp"
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="forApp">신청용</label>
                </div>
                <div className="plan_radio_btn">
                  <input
                    type="radio"
                    name="choicePlan"
                    id="forConvention"
                    value="forConvention"
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="forConvention">협약용</label>
                </div>
              </div>
              <div className="plan_secure">
                <span className="plan_secure_text">보안등급</span>
                <img src={questionMark} alt="questionMark" />
                <div className="plan_btn_wrapper">
                  <div className="plan_radio_btn">
                    <input
                      type="radio"
                      name="choiceSecure"
                      id="general"
                      value="general"
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="general">일반</label>
                  </div>
                  <div className="plan_radio_btn">
                    <input
                      type="radio"
                      name="choiceSecure"
                      id="security"
                      value="security"
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="security">보안</label>
                  </div>
                </div>
              </div>
              <span>
                ■ 국가연구개발혁신법 시행규칙 [별지 제1호서식] &lt;개정 2022. 1.
                4.&gt;
              </span>
            </div>
            <div className="plan_grid_items rnd_plan_item">
              <div className="plan_grid_item ">
                <p>중앙행정기관명</p>
                <input
                  type="text"
                  name="rnd_plan"
                  value={pageOneData.rnd_plan[0]}
                  onChange={(e) => handleChange(e, 0)}
                  placeholder="산업통상자원부"
                />
              </div>
              <div className="plan_grid_item">
                <p>
                  사업명
                  <br />
                  <span className="extra_text">세부 사업명</span>
                </p>
                <input
                  type="text"
                  name="rnd_plan"
                  value={pageOneData.rnd_plan[1]}
                  onChange={(e) => handleChange(e, 1)}
                />
              </div>
              <div className="plan_grid_item">
                <p>
                  전문 기관명
                  <br />
                  <span className="extra_text">(해당 시 작성)</span>
                </p>
                <input
                  type="text"
                  name="rnd_plan"
                  value={pageOneData.rnd_plan[2]}
                  onChange={(e) => handleChange(e, 2)}
                  placeholder="한국산업기술평가관리원"
                />
              </div>
              <div className="plan_grid_item">
                <p>
                  사업명
                  <br />
                  <span className="extra_text">내역 사업명</span>
                </p>
                <input
                  type="text"
                  name="rnd_plan"
                  value={pageOneData.rnd_plan[3]}
                  onChange={(e) => handleChange(e, 3)}
                  placeholder="해당시 작성"
                />
              </div>
              <div className="plan_grid_item">
                <p>공고번호</p>
                <input
                  type="text"
                  name="rnd_plan"
                  value={pageOneData.rnd_plan[4]}
                  onChange={(e) => handleChange(e, 4)}
                  placeholder="ex) 제 2022-380호"
                />
              </div>
              <div className="plan_grid_item">
                <p>
                  총괄연구개발
                  <br />
                  식별번호
                </p>

                <input
                  type="text"
                  name="rnd_plan"
                  value={pageOneData.rnd_plan[5]}
                  onChange={(e) => handleChange(e, 5)}
                  placeholder="해당시 작성"
                />
              </div>
              <div className="plan_grid_item right_align_item">
                <p>연구개발과제번호</p>
                <input
                  type="text"
                  name="rnd_plan"
                  value={pageOneData.rnd_plan[6]}
                  onChange={(e) => handleChange(e, 6)}
                />
              </div>
            </div>
            <div className="plan_select">
              <p>선정방식</p>
              <FirstInfoText idx={1} />
              <div className="plan_radio_btn">
                <input
                  type="radio"
                  name="choiceSelect"
                  id="choice"
                  checked={choice}
                  onClick={() => setChoice(!choice)}
                  onChange={handleRadioChange}
                />
                <label htmlFor="choice">정책지정</label>
              </div>
              <div className="plan_contest_btn">
                <span>공 모 :</span>
                <div className="plan_radio_btn">
                  <input
                    type="radio"
                    name="choiceContest"
                    id="designate"
                    value="designate"
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="designate">지정공모</label>
                </div>
                <div className="plan_radio_btn">
                  <input
                    type="radio"
                    name="choiceContest"
                    id="item"
                    value="item"
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="item">품목공모</label>
                </div>
                <div className="plan_radio_btn">
                  <input
                    type="radio"
                    name="choiceContest"
                    id="field"
                    value="field"
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="field">분야공모</label>
                </div>
                <div className="plan_radio_btn">
                  <input
                    type="radio"
                    name="choiceContest"
                    id="free"
                    value="free"
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="free">자유공모</label>
                </div>
              </div>
            </div>
            <div className="tech_classification">
              <p className="big_text">기술분류</p>
              <div className="tech_classification_item">
                <div className="text_wrapper">
                  <span className="small_text">국가과학기술표준분류</span>
                  <img src={questionMark} alt="questionMark" />
                </div>
                <div className="tech_classification_code">
                  <p>1순위 소분류 코드명</p>
                  <input
                    type="text"
                    name="standard_classification"
                    value={pageOneData.standard_classification[0]}
                    onChange={(e) => handleChange(e, 0)}
                    placeholder="ND0701"
                  />
                </div>
                <div className="tech_classification_code">
                  <p>2순위 소분류 코드명</p>
                  <input
                    type="text"
                    name="standard_classification"
                    value={pageOneData.standard_classification[1]}
                    onChange={(e) => handleChange(e, 1)}
                    placeholder="ND0703"
                  />
                </div>
                <div className="tech_classification_code">
                  <p>3순위 소분류 코드명</p>
                  <input
                    type="text"
                    name="standard_classification"
                    value={pageOneData.standard_classification[2]}
                    onChange={(e) => handleChange(e, 2)}
                    placeholder="ND0710"
                  />
                </div>
              </div>
              <div className="tech_classification_item">
                <div className="text_wrapper">
                  <p className="small_text">
                    부처기술분류
                    <br />
                    <span className="extra_text">(해당 시 작성)</span>
                  </p>
                  <img src={questionMark} alt="questionMark" />
                </div>
                <div className="tech_classification_code">
                  <p>1순위 소분류 코드명</p>
                  <input
                    type="text"
                    name="technology_classification"
                    value={pageOneData.technology_classification[0]}
                    onChange={(e) => handleChange(e, 0)}
                    placeholder="SF0709"
                  />
                </div>
                <div className="tech_classification_code">
                  <p>2순위 소분류 코드명</p>
                  <input
                    type="text"
                    name="technology_classification"
                    value={pageOneData.technology_classification[1]}
                    onChange={(e) => handleChange(e, 1)}
                    placeholder="ND0701"
                  />
                </div>
                <div className="tech_classification_code">
                  <p>3순위 소분류 코드명</p>
                  <input
                    type="text"
                    name="technology_classification"
                    value={pageOneData.technology_classification[2]}
                    onChange={(e) => handleChange(e, 2)}
                    placeholder="LB1105"
                  />
                </div>
              </div>
            </div>
            <div className="tech_name">
              <div className="tech_name_text">
                <p className="big_text">총괄연구개발명</p>
                <p className="extra_text">(해당 시 작성)</p>
                <FirstInfoText idx={2} />
              </div>
              <div className="tech_name_item rnd_name">
                <p className="small_text">국문</p>
                <input
                  type="text"
                  name="general_rnd_name"
                  value={pageOneData.general_rnd_name[0]}
                  onChange={(e) => handleChange(e, 0)}
                  placeholder="입력하세요."
                />
              </div>
              <div className="tech_name_item rnd_name">
                <p className="small_text">영문</p>
                <input
                  type="text"
                  name="general_rnd_name"
                  value={pageOneData.general_rnd_name[1]}
                  onChange={(e) => handleChange(e, 1)}
                  placeholder="입력하세요."
                />
              </div>
            </div>
            <div className="tech_name">
              <div className="tech_name_text">
                <p className="big_text">연구개발과제명</p>
                <FirstInfoText idx={3} />
              </div>
              <div className="tech_name_item rnd_name">
                <p className="small_text">국문</p>
                <input
                  type="text"
                  name="task_rnd_name"
                  value={pageOneData.task_rnd_name[0]}
                  onChange={(e) => handleChange(e, 0)}
                  placeholder="입력하세요."
                />
              </div>
              <div className="tech_name_item rnd_name">
                <p className="small_text">영문</p>
                <input
                  type="text"
                  name="task_rnd_name"
                  value={pageOneData.task_rnd_name[1]}
                  onChange={(e) => handleChange(e, 1)}
                  placeholder="입력하세요."
                />
              </div>
            </div>
            <div className="grid_wrapper agency_and_director">
              <p className="big_text">주관연구개발기관</p>
              <div className="plan_grid_items">
                <div className="plan_grid_item">
                  <p>기관명</p>
                  <input
                    type="text"
                    name="development_agency"
                    value={pageOneData.development_agency[0]}
                    onChange={(e) => handleChange(e, 0)}
                    placeholder="주관연구개발기관명"
                  />
                </div>
                <div className="plan_grid_item">
                  <p>사업자등록번호</p>
                  <input
                    type="text"
                    name="development_agency"
                    value={pageOneData.development_agency[1]}
                    onChange={(e) => handleChange(e, 1)}
                    placeholder="XXX-XX-XXXXX"
                  />
                </div>
                <div className="plan_grid_item">
                  <p>주소</p>
                  <input
                    type="text"
                    name="development_agency"
                    value={pageOneData.development_agency[2]}
                    onChange={(e) => handleChange(e, 2)}
                    placeholder="(우)"
                  />
                </div>
                <div className="plan_grid_item">
                  <p>법인등록번호</p>
                  <input
                    type="text"
                    name="development_agency"
                    value={pageOneData.development_agency[3]}
                    onChange={(e) => handleChange(e, 3)}
                    placeholder="XXXXXX-XXXXXXX"
                  />
                </div>
              </div>
            </div>
            <div className="grid_wrapper agency_and_director">
              <p className="big_text">연구책임자</p>
              <div className="plan_grid_items">
                <div className="plan_grid_item">
                  <p className="text_letter_spacing">성명</p>
                  <input
                    type="text"
                    name="research_director"
                    value={pageOneData.research_director[0]}
                    onChange={(e) => handleChange(e, 0)}
                    placeholder="입력하세요."
                  />
                </div>
                <div className="plan_grid_item">
                  <p className="text_letter_spacing">직위</p>
                  <input
                    type="text"
                    name="research_director"
                    value={pageOneData.research_director[1]}
                    onChange={(e) => handleChange(e, 1)}
                    placeholder="ex)연구소장,수석연구원,책임연구원"
                  />
                </div>
                <div className="plan_grid_item">
                  <p>직장전화</p>
                  <input
                    type="text"
                    name="research_director"
                    value={pageOneData.research_director[2]}
                    onChange={(e) => handleChange(e, 2)}
                  />
                </div>
                <div className="plan_grid_item">
                  <p>휴대전화</p>
                  <input
                    type="text"
                    name="research_director"
                    value={pageOneData.research_director[3]}
                    onChange={(e) => handleChange(e, 3)}
                  />
                </div>
                <div className="plan_grid_item">
                  <p>전자우편</p>
                  <input
                    type="text"
                    name="research_director"
                    value={pageOneData.research_director[4]}
                    onChange={(e) => handleChange(e, 4)}
                  />
                </div>
                <div className="plan_grid_item">
                  <p>국가연구자번호</p>
                  <input
                    type="text"
                    name="research_director"
                    value={pageOneData.research_director[5]}
                    onChange={(e) => handleChange(e, 5)}
                  />
                </div>
              </div>
            </div>
            <div className="research_period">
              <div className="research_text">
                <div className="research_text_left">
                  <p className="big_text">연구개발기간</p>
                  <FirstInfoText idx={4} />
                  <BringingUpIcon />
                </div>
                <div className="research_text_right">
                  {/* <button onClick={() => addTableRows()}>
                    <img src={buttonIcon} alt="addColIcon" />
                  </button> */}
                </div>
              </div>
              <div className="research_period_wrapper">
                <table className="research_period_table">
                  <colgroup>
                    <col width="100px" />
                    <col width="90px" />
                    <col width="90px" />
                    <col width="200px" />
                    <col width="30px" />
                    <col width="200px" />
                    <col width="60px" />
                    <col width="75px" />
                    <col width="40px" />
                  </colgroup>
                  <thead>
                    <tr key={1000}>
                      <th colSpan="3" className="table_item_all">
                        전체
                      </th>
                      <th>
                        <DatePicker
                          placeholderText="YYYY.MM.DD"
                          dateFormat="yyyy.MM.dd"
                          fixedHeight
                          selected={periodHeadData.startDate}
                          value={periodHeadData.startDate}
                          onChange={(date) => {
                            periodHeadData.startDate = date;
                            setPeriodHeadData({
                              ...periodHeadData,
                            });
                          }}
                        />
                      </th>
                      <th>
                        <span> ~ </span>
                      </th>
                      <th>
                        <DatePicker
                          placeholderText="YYYY.MM.DD"
                          dateFormat="yyyy.MM.dd"
                          fixedHeight
                          selected={periodHeadData.endDate}
                          value={periodHeadData.endDate}
                          onChange={(date) => {
                            periodHeadData.endDate = date;
                            setPeriodHeadData({
                              ...periodHeadData,
                            });
                          }}
                        />
                      </th>
                      <th>
                        <input
                          type="text"
                          className="back"
                          value={periodHeadData.year}
                          name="step"
                          onChange={(e) => {
                            periodHeadData.year = e.target.value;
                            setPeriodHeadData({ ...periodHeadData });
                          }}
                        />
                        <span>년</span>
                      </th>
                      <th>
                        <input
                          type="text"
                          className="back"
                          value={periodHeadData.month}
                          name="step"
                          onChange={(e) => {
                            periodHeadData.month = e.target.value;
                            setPeriodHeadData({ ...periodHeadData });
                          }}
                        />
                        <span>개월</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {periodRowsData.map((data, index) => {
                      const { step, theYear, year, month, placeholder } = data;
                      return (
                        <tr key={index}>
                          {index === 0 && (
                            <td
                              rowSpan={periodRowsData.length}
                              className="step"
                            >
                              <p className="small_text">단 계</p>
                              <p className="extra_text">(해당 시 작성)</p>
                              <img src={questionMark} alt="questionMark" />
                            </td>
                          )}
                          <td className="step">
                            <input
                              type="text"
                              className="front"
                              value={step || ""}
                              name="step"
                              placeholder={placeholder[0]}
                              onChange={(e) => {
                                handlePeriodChange(index, e);
                              }}
                            />
                            <span>단 계</span>
                          </td>
                          <td className="step">
                            <input
                              type="text"
                              className="front"
                              value={theYear || ""}
                              placeholder={placeholder[1]}
                              name="theYear"
                              onChange={(e) => {
                                handlePeriodChange(index, e);
                              }}
                            />
                            <span>년 차</span>
                          </td>
                          <td>
                            <DatePicker
                              placeholderText="YYYY.MM.DD"
                              dateFormat="yyyy.MM.dd"
                              fixedHeight
                              selected={periodRowsData[index].startDate || ""}
                              value={periodRowsData[index].startDate}
                              onChange={(e) => handleDateChange(index, e, 0)}
                            />
                          </td>
                          <td>
                            <span>~</span>
                          </td>
                          <td>
                            <DatePicker
                              placeholderText="YYYY.MM.DD"
                              dateFormat="yyyy.MM.dd"
                              fixedHeight
                              selected={periodRowsData[index].endDate || ""}
                              value={periodRowsData[index].endDate}
                              onChange={(e) => handleDateChange(index, e, 1)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="back"
                              value={year || ""}
                              name="year"
                              onChange={(e) => {
                                handlePeriodChange(index, e);
                              }}
                            />
                            <span>년</span>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="back"
                              value={month || ""}
                              name="month"
                              onChange={(e) => {
                                handlePeriodChange(index, e);
                              }}
                            />
                            <span>개월</span>
                          </td>
                          <td>
                            {/* <button
                              className="icon_del_trashcan"
                              onClick={() => deleteTableRows(index)}
                            /> */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="research_cost">
                <div className="research_text">
                  <div className="research_text_left">
                    <p className="big_text">연구개발비</p>
                    <FirstInfoText idx={5} />
                    <BringingUpIcon />
                  </div>
                  <div className="research_text_right">
                    <p className="extra_text">
                      단위 : <span>천원</span>
                    </p>
                  </div>
                </div>
                <div className="research_cost_table">
                  <table>
                    <thead>
                      <tr style={{ height: "21px" }}>
                        <th colSpan="2" rowSpan="3">
                          <span className="text_letter_spacing_10">총계</span>
                        </th>
                        <th rowSpan="2">
                          <span>
                            정부지원
                            <br /> 연구개발비
                          </span>
                        </th>
                        <th
                          colSpan="2"
                          rowSpan="2"
                          style={{ backgroundColor: "#d8d8d8" }}
                        >
                          <span>
                            기관부담
                            <br />
                            연구개발비
                          </span>
                        </th>
                        <th colSpan="4">
                          <span>그외 기관 등의 지원금</span>
                        </th>
                        <th
                          colSpan="3"
                          rowSpan="2"
                          style={{
                            backgroundColor: "rgba(216, 216, 216, 0.5)",
                          }}
                        >
                          <span className="text_letter_spacing_10">합계</span>
                        </th>
                        <th rowSpan="3">
                          <span>연구개발비 외 지원금</span>
                        </th>
                        <th rowSpan="3" style={{ width: "30px" }}>
                          <span>&nbsp;</span>
                        </th>
                      </tr>
                      <tr style={{ height: "21px" }}>
                        <th colSpan="2" style={{ backgroundColor: "#d8d8d8" }}>
                          <span>지방자치단체</span>
                        </th>
                        <th
                          colSpan="2"
                          style={{
                            backgroundColor: "rgba(216, 216, 216, 0.25)",
                          }}
                        >
                          <span className="text_letter_spacing_10">기타</span>
                        </th>
                      </tr>
                      <tr style={{ height: "38px" }}>
                        <th
                          style={{
                            backgroundColor: "rgba(216, 216, 216, 0.5)",
                          }}
                        >
                          <span className="text_letter_spacing_10">현금</span>
                        </th>
                        <th>
                          <span className="text_letter_spacing_10">현금</span>
                        </th>
                        <th
                          style={{
                            backgroundColor: "rgba(216, 216, 216, 0.5)",
                          }}
                        >
                          <span className="text_letter_spacing_10">현물</span>
                        </th>
                        <th>
                          <span className="text_letter_spacing_10">현금</span>
                        </th>
                        <th
                          style={{
                            backgroundColor: "rgba(216, 216, 216, 0.5)",
                          }}
                        >
                          <span className="text_letter_spacing_10">현물</span>
                        </th>
                        <th>
                          <span className="text_letter_spacing_10">현금</span>
                        </th>
                        <th
                          style={{
                            backgroundColor: "rgba(216, 216, 216, 0.5)",
                          }}
                        >
                          <span className="text_letter_spacing_10">현물</span>
                        </th>
                        <th>
                          <span className="text_letter_spacing_10">현금</span>
                        </th>
                        <th
                          style={{
                            backgroundColor: "rgba(216, 216, 216, 0.5)",
                          }}
                        >
                          <span className="text_letter_spacing_10">현물</span>
                        </th>
                        <th>
                          <span className="text_letter_spacing_10">합계</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          <span>총계</span>
                        </td>
                        <td>
                          <input
                            type="text"
                            value={costHeadData.gov_cash}
                            name="gov_cash"
                            onChange={(e) => {
                              costHeadData.gov_cash = e.target.value;
                              setCostHeadData({ ...costHeadData });
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={costHeadData.inst_cash}
                            name="gov_cash"
                            onChange={(e) => {
                              costHeadData.inst_cash = e.target.value;
                              setCostHeadData({ ...costHeadData });
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={costHeadData.inst_stocks}
                            name="gov_cash"
                            onChange={(e) => {
                              costHeadData.inst_stocks = e.target.value;
                              setCostHeadData({ ...costHeadData });
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={costHeadData.local_cash}
                            name="gov_cash"
                            onChange={(e) => {
                              costHeadData.local_cash = e.target.value;
                              setCostHeadData({ ...costHeadData });
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={costHeadData.local_stocks}
                            name="gov_cash"
                            onChange={(e) => {
                              costHeadData.local_stocks = e.target.value;
                              setCostHeadData({ ...costHeadData });
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={costHeadData.etc_cash}
                            name="gov_cash"
                            onChange={(e) => {
                              costHeadData.etc_cash = e.target.value;
                              setCostHeadData({ ...costHeadData });
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={costHeadData.etc_stocks}
                            name="gov_cash"
                            onChange={(e) => {
                              costHeadData.etc_stocks = e.target.value;
                              setCostHeadData({ ...costHeadData });
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={costHeadData.sum_cash}
                            name="gov_cash"
                            onChange={(e) => {
                              costHeadData.sum_cash = e.target.value;
                              setCostHeadData({ ...costHeadData });
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={costHeadData.sum_stocks}
                            name="gov_cash"
                            onChange={(e) => {
                              costHeadData.sum_stocks = e.target.value;
                              setCostHeadData({ ...costHeadData });
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={costHeadData.sum_sum}
                            name="gov_cash"
                            onChange={(e) => {
                              costHeadData.sum_sum = e.target.value;
                              setCostHeadData({ ...costHeadData });
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={costHeadData.extraCost}
                            name="gov_cash"
                            onChange={(e) => {
                              costHeadData.extraCost = e.target.value;
                              setCostHeadData({ ...costHeadData });
                            }}
                          />
                        </td>
                      </tr>
                      {costRowsData.map((data, index) => {
                        const {
                          step,
                          theYear,
                          gov_cash,
                          inst_cash,
                          inst_stocks,
                          local_cash,
                          local_stocks,
                          etc_cash,
                          etc_stocks,
                          sum_cash,
                          sum_stocks,
                          sum_sum,
                          extraCost,
                          placeholder,
                        } = data;
                        return (
                          <tr key={index}>
                            <td>
                              <input
                                type="text"
                                value={step || ""}
                                name="step"
                                placeholder={placeholder[0]}
                                onChange={(e) => handleCostChange(index, e)}
                                style={{ width: "35px" }}
                              />
                              <span>단계</span>
                            </td>
                            <td>
                              <input
                                type="text"
                                value={theYear || ""}
                                name="theYear"
                                placeholder={placeholder[1]}
                                onChange={(e) => handleCostChange(index, e)}
                                style={{ width: "35px" }}
                              />
                              <span>년차</span>
                            </td>
                            <td>
                              <input
                                type="text"
                                value={gov_cash || ""}
                                name="gov_cash"
                                onChange={(e) => handleCostChange(index, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={inst_cash || ""}
                                name="inst_cash"
                                onChange={(e) => handleCostChange(index, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={inst_stocks || ""}
                                name="inst_stocks"
                                onChange={(e) => handleCostChange(index, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={local_cash || ""}
                                name="local_cash"
                                onChange={(e) => handleCostChange(index, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={local_stocks || ""}
                                name="local_stocks"
                                onChange={(e) => handleCostChange(index, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={etc_cash || ""}
                                name="etc_cash"
                                onChange={(e) => handleCostChange(index, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={etc_stocks || ""}
                                name="etc_stocks"
                                onChange={(e) => handleCostChange(index, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={sum_cash || ""}
                                name="sum_cash"
                                onChange={(e) => handleCostChange(index, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={sum_stocks || ""}
                                name="sum_stocks"
                                onChange={(e) => handleCostChange(index, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={sum_sum || ""}
                                name="sum_sum"
                                onChange={(e) => handleCostChange(index, e)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={extraCost || ""}
                                name="extraCost"
                                onChange={(e) => handleCostChange(index, e)}
                              />
                            </td>
                            <td style={{ width: "24px" }}>
                              {/* <button
                                className="icon_del_trashcan"
                                onClick={() => deleteTableRows(index)}
                              /> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="research_agency">
                  <div className="research_text">
                    <div className="research_text_left">
                      <p className="big_text">공동연구개발기관 등</p>
                      <FirstInfoText idx={6} />
                      <BringingUpIcon />
                    </div>
                    <div className="research_text_right">
                      <p className="extra_text">해당 시 작성</p>
                      {/* <button onClick={addAgencyTableRows}>
                        <img src={buttonIcon} alt="addColIcon" />
                      </button> */}
                    </div>
                  </div>
                  <div className="research_agency_table">
                    <table>
                      <colgroup>
                        <col width="126px" />
                        <col width="126px" />
                        <col width="103px" />
                        <col width="95px" />
                        <col width="126px" />
                        <col width="126px" />
                        <col width="103px" />
                        <col width="103px" />
                        <col width="42px" />
                      </colgroup>
                      <thead>
                        <tr style={{ height: "21px" }}>
                          <th rowSpan="2"></th>
                          <th rowSpan="2">기관명</th>
                          <th rowSpan="2">책임자</th>
                          <th rowSpan="2">직&emsp;위</th>
                          <th rowSpan="2">휴대전화</th>
                          <th rowSpan="2">전자우편</th>
                          <th
                            colSpan="2"
                            style={{ backgroundColor: "#d8d8d8" }}
                          >
                            비고
                          </th>
                          <th rowSpan="2">&nbsp;</th>
                        </tr>
                        <tr style={{ height: "21px" }}>
                          <th
                            style={{
                              backgroundColor: "rgba(216, 216, 216, 0.5)",
                            }}
                          >
                            <div>
                              역&emsp;할
                              <i className="icon_info_with_white_bg" />
                            </div>
                          </th>
                          <th>
                            <div>
                              기관 유형
                              <i className="icon_info_with_white_bg" />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {agencyRowsData.map((data, index) => {
                          const {
                            select_option,
                            organization_name,
                            manager,
                            position,
                            phone_number,
                            email,
                            role,
                            type,
                          } = data;
                          return (
                            <tr key={index}>
                              <td>
                                <select
                                  name="select_option"
                                  value={select_option}
                                  onChange={(e) => handleAgencyChange(index, e)}
                                >
                                  <option value="">선택하세요.</option>
                                  <option value="1">공동연구개발기관</option>
                                  <option value="2">위탁연구개발기관</option>
                                  <option value="3">
                                    연구개발기관 외 기관
                                  </option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={organization_name || ""}
                                  name="organization_name"
                                  onChange={(e) => handleAgencyChange(index, e)}
                                  style={{ width: "125px" }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={manager || ""}
                                  name="manager"
                                  onChange={(e) => handleAgencyChange(index, e)}
                                  style={{ width: "100px" }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={position || ""}
                                  name="position"
                                  onChange={(e) => handleAgencyChange(index, e)}
                                  style={{ width: "90px" }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={phone_number || ""}
                                  name="phone_number"
                                  onChange={(e) => handleAgencyChange(index, e)}
                                  style={{ width: "125px" }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={email || ""}
                                  name="email"
                                  onChange={(e) => handleAgencyChange(index, e)}
                                  style={{ width: "125px" }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={role || ""}
                                  name="role"
                                  onChange={(e) => handleAgencyChange(index, e)}
                                  style={{ width: "100px" }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={type || ""}
                                  name="type"
                                  onChange={(e) => handleAgencyChange(index, e)}
                                  style={{ width: "100px" }}
                                />
                              </td>
                              <td>
                                {/* <button
                                  className="icon_del_trashcan"
                                  onClick={() => deleteAgencyTableRows(index)}
                                /> */}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="research_joint">
                    <div className="research_joint_text">
                      <p className="big_text">연구개발과제 실무담당자</p>
                      <FirstInfoText idx={7} />
                      <BringingUpIcon />
                    </div>
                    <div className="grid_wrapper agency_and_director">
                      <div className="plan_grid_items">
                        <div className="plan_grid_item">
                          <p className="text_letter_spacing">성명</p>
                          <input
                            type="text"
                            name="practical_person"
                            value={pageOneData.practical_person[0]}
                            onChange={(e) => handleChange(e, 0)}
                            placeholder="입력하세요."
                          />
                        </div>
                        <div className="plan_grid_item">
                          <p className="text_letter_spacing">직위</p>
                          <input
                            type="text"
                            name="practical_person"
                            value={pageOneData.practical_person[1]}
                            onChange={(e) => handleChange(e, 1)}
                            placeholder="ex)연구소장,수석연구원,책임연구원"
                          />
                        </div>
                        <div className="plan_grid_item">
                          <p>직장전화</p>
                          <input
                            type="text"
                            name="practical_person"
                            value={pageOneData.practical_person[2]}
                            onChange={(e) => handleChange(e, 2)}
                          />
                        </div>
                        <div className="plan_grid_item">
                          <p>휴대전화</p>
                          <input
                            type="text"
                            name="practical_person"
                            value={pageOneData.practical_person[3]}
                            onChange={(e) => handleChange(e, 3)}
                          />
                        </div>
                        <div className="plan_grid_item">
                          <p>전자우편</p>
                          <input
                            type="text"
                            name="practical_person"
                            value={pageOneData.practical_person[4]}
                            onChange={(e) => handleChange(e, 4)}
                          />
                        </div>
                        <div className="plan_grid_item">
                          <p>국가연구자번호</p>
                          <input
                            type="text"
                            name="practical_person"
                            value={pageOneData.practical_person[5]}
                            onChange={(e) => handleChange(e, 5)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="end_text">
          <p>
            관련 법령 및 규정과 모든 의무사항을 준수하면서 이 연구개발과제를
            성실하게 수행하기 위하여 연구개발계획서를 제출합니다.
            <br />
            아울러 이 연구개발계획서에 기재된 내용이 사실임을 확인하며, 만약
            사실이 아닌 경우 연구개발과제 선정 취소, 협약 해약 등의 불이익도
            감수하겠습니다.
          </p>
        </div>
        <div className="prev_next_button">
          <button
            className="prev_button"
            onClick={() => alert("첫번째 페이지입니다.")}
          >
            <img src={prevButton} alt="prevButton" />
          </button>
          <button
            className="next_button"
            onClick={() => {
              sendData();
              history.push("/business/rnd/first");
            }}
          >
            <img src={nextButton} alt="nextButton" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RnDPlanFirst;

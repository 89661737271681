import { Link, useLocation } from "react-router-dom/cjs/react-router-dom";
import checkOffIcon from "../../assets/images/businessPlan/icon-checkbox-off.svg";
import checkOnIcon from "../../assets/images/businessPlan/icon-checkbox-on.svg";
import "./R&D/planOne/css/SidebarRnd.scss";

const SidebarRnd = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const onClassCheck = (step, type) => {
    if (type === "class") {
      if (pathname.includes(step)) {
        return "on";
      } else {
        return "";
      }
    } else if (type === "icon") {
      if (pathname.includes(step)) {
        return <img src={checkOnIcon} alt="checkOnIcon" />;
      } else {
        return <img src={checkOffIcon} alt="checkOffIcon" />;
      }
    }
  };

  return (
    <div id="SidebarRnd">
      <ul>
        <li className={onClassCheck("first", "class")}>
          <Link to="/business/rnd/first">
            {onClassCheck("first", "icon")}연구개발계획서
          </Link>
        </li>
        <li className={onClassCheck("second", "class")}>
          <Link to="/business/rnd/second">
            {onClassCheck("second", "icon")}요약문
          </Link>
        </li>
        <li className={onClassCheck("third", "class")}>
          <Link to="/business/rnd/third">
            {onClassCheck("third", "icon")}본문1
          </Link>
        </li>
        <li
          className={
            onClassCheck("fourth", "class") || onClassCheck("fifth", "class")
          }
        >
          <Link to="/business/rnd/fourth">
            {pathname.includes("fourth") || pathname.includes("fifth") ? (
              <img src={checkOnIcon} alt="checkOnIcon" />
            ) : (
              <img src={checkOffIcon} alt="checkOffIcon" />
            )}
            본문2
          </Link>
          <ul>
            <li className={onClassCheck("fourth", "class")}>
              <Link to="/business/rnd/fourth">Step. 1 연구개발기관 현황</Link>
            </li>
            <li className={onClassCheck("fifth", "class")}>
              <Link to="/business/rnd/fifth">
                Step. 2 연구개발비 사용에 관한 계획
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default SidebarRnd;

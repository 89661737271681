import { useState, useEffect } from "react";
import "./scss/GroupwareCashflow.scss";

const BizActParent = ({ isIncome, addCashItem, monthSum }) => {
  const [months, setMonths] = useState(monthSum);

  useEffect(() => {
    setMonths(monthSum);
  }, [monthSum]);

  return (
    <div className="parent">
      <div className={`label ${isIncome === 1 ? "green" : "red"}`}>
        {isIncome === 1 ? "수입" : "지출"}
        {/* <span className="num">(8)</span> */}
      </div>
      <ul>
        {months.map((month, idx) => (
          <li key={idx}>
            <input
              type="text"
              placeholder="0"
              value={month.value ? month.value : ""}
              readOnly
              className={`${
                isIncome === 1 ? "week_green_bg" : "week_red_bg"
              } align_right`}
            />
          </li>
        ))}
        {/* <li className="btn_list">
          <button className="add" onClick={addCashItem}>
            +
          </button>
        </li> */}
      </ul>
    </div>
  );
};

export default BizActParent;

import { useState } from "react";

const CashEtcItem = ({
  id,
  category_name,
  type,
  is_income,
  cash_amount,
  cash_currency,
  currency_exchange,
  base_date,
  deleteCashEtcItem,
  handleUpdateCashItem,
}) => {
  const formatNumberWithCommas = (number) => {
    // 숫자를 받아 콤마가 포함된 문자열로 변환하는 함수
    if (number) {
      const numericValue = parseInt(number, 10);
      return numericValue.toLocaleString();
    }
    return "";
  };

  const [categoryName, setCategoryName] = useState(category_name);
  const [cashAmount, setCashAmount] = useState(
    formatNumberWithCommas(cash_amount)
  );
  const [cashCurrency, setCashCurrency] = useState(cash_currency);
  const [currencyExchange, setCurrencyExchange] = useState(currency_exchange);

  const handleCashAmountChange = (e) => {
    // 입력값에서 숫자와 콤마만 유지
    const inputValue = e.target.value.replace(/[^0-9,]/g, "");
    // 콤마를 제거하고 숫자만 추출
    const numericValue = inputValue.replace(/,/g, "");

    // 숫자를 형식화하여 콤마 추가
    const formattedValue = numericValue
      ? parseInt(numericValue, 10).toLocaleString()
      : "";

    // 입력 필드에 형식화된 값을 표시하기 위해 상태 업데이트
    setCashAmount(formattedValue);

    // handleUpdateCashItem 호출 시 콤마를 제거한 숫자로 변환하여 전달
    handleUpdateCashItem(id, { cash_amount: numericValue }, {}, 1);
  };

  return (
    <tr id={id}>
      <th>기타</th>
      <td colSpan="2">
        <span className="etc_txt">
          <input
            type="text"
            placeholder="예) 현금"
            className="item_name"
            value={categoryName}
            onChange={(e) => {
              setCategoryName(e.target.value);
              handleUpdateCashItem(
                id,
                {},
                { category_name: e.target.value },
                1
              );
            }}
          />
        </span>
        <input
          type="text"
          placeholder="0"
          className="etc_in align_right pr-10"
          value={cashAmount}
          onChange={(e) => {
            setCashAmount(e.target.value);
            handleCashAmountChange(e);
          }}
        />
      </td>
      <td>
        <select
          value={cashCurrency}
          onChange={(e) => {
            setCashCurrency(e.target.value);
            handleUpdateCashItem(id, { cash_currency: e.target.value }, {}, 1);
          }}
        >
          <option value="0">천원</option>
          {/* <option value="1">USD</option>
          <option value="2">EURO</option>
          <option value="3">JPY</option>
          <option value="4">CNY</option>
          <option value="5">VND</option> */}
        </select>
      </td>
      <td>
        <span className="blue none">예상환전금액: </span>
        <input
          type="text"
          placeholder="0"
          value={currencyExchange}
          className="none"
          onChange={(e) => {
            setCurrencyExchange(e.target.value);
            handleUpdateCashItem(
              id,
              { currency_exchange: e.target.value },
              {},
              1
            );
          }}
        />
        {/* 원 */}
      </td>
      <td>
        <button className="del etc" onClick={() => deleteCashEtcItem(id)}>
          -
        </button>
      </td>
    </tr>
  );
};

export default CashEtcItem;

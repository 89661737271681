import "./scss/GroupwareCashflow.scss";

const CashflowStep = ({ step }) => {
  const stepInfo = ["가용잔고", "영업매출", "영업활동", "재무활동", "투자활동"];
  return (
    <div className="steps">
      <div className="wrap">
        <ul>
          {stepInfo.map((item, idx) => (
            <li
              key={idx}
              className={`bg_wh rounded ${idx + 1 === step ? "on" : ""}`}
            >
              <div className="name">
                <p>STEP.{idx + 1}</p>
                <p>{item}</p>
              </div>
              <div class="bar rounded"></div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CashflowStep;

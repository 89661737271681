import { Link } from "react-router-dom/cjs/react-router-dom";
import "./scss/Top.scss";

const Top = ({ page, date = "" }) => {
  return (
    <div id="Top">
      <div className={`top_bar ${page === "step" ? "green_bg" : "blue_bg"}`}>
        <div className="wrap">
          <h3>NOW</h3>
          {date === "" || date === 1 ? (
            <p>귀사의 RUNWAY를 쉽고 빠르게 계산해 보세요</p>
          ) : (
            <div className="alert">
              <p className="last">
                Last Updated <strong>+{date - 1}일</strong>
              </p>
              <p>CASHFLOW 업데이트 하실때가 됐네요!</p>
              <Link to="/cashflow/business/balanceIns" className="to_update">
                업데이트 하러 가기
              </Link>
            </div>
          )}
          {page === "step" ? <p className="start">작성중</p> : ""}
        </div>
      </div>
    </div>
  );
};

export default Top;

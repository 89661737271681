import { useState } from "react";

const CashItem = ({
  id,
  category_name,
  type,
  is_income,
  bank_id,
  bank_account,
  cash_amount,
  cash_currency,
  currency_exchange,
  base_date,
  deleteCashItem,
  handleUpdateCashItem,
}) => {
  const formatNumberWithCommas = (number) => {
    // 숫자를 받아 콤마가 포함된 문자열로 변환하는 함수
    if (number) {
      const numericValue = parseInt(number, 10);
      return numericValue.toLocaleString();
    }
    return "";
  };

  const [categoryName, setCategoryName] = useState(category_name);
  const [bankId, setBankId] = useState(bank_id);
  const [bankAccount, setBankAccount] = useState(bank_account);
  const [cashAmount, setCashAmount] = useState(
    formatNumberWithCommas(cash_amount)
  );
  const [cashCurrency, setCashCurrency] = useState(cash_currency);
  const [currencyExchange, setCurrencyExchange] = useState(currency_exchange);

  const handleCashAmountChange = (e) => {
    // 입력값에서 숫자와 콤마만 유지
    const inputValue = e.target.value.replace(/[^0-9,]/g, "");
    // 콤마를 제거하고 숫자만 추출
    const numericValue = inputValue.replace(/,/g, "");

    // 숫자를 형식화하여 콤마 추가
    const formattedValue = numericValue
      ? parseInt(numericValue, 10).toLocaleString()
      : "";

    // 입력 필드에 형식화된 값을 표시하기 위해 상태 업데이트
    setCashAmount(formattedValue);

    // handleUpdateCashItem 호출 시 콤마를 제거한 숫자로 변환하여 전달
    handleUpdateCashItem(id, { cash_amount: numericValue }, {}, 0);
  };

  return (
    <tr id={id}>
      <th>
        <input
          type="text"
          placeholder="예) 보통예금"
          className="item_name"
          value={categoryName}
          onChange={(e) => {
            setCategoryName(e.target.value);
            handleUpdateCashItem(id, {}, { category_name: e.target.value }, 0);
          }}
        />
      </th>
      <td>
        <div className="bank">
          {/* <input
            type="text"
            placeholder="은행명"
            value={bankId}
            onChange={(e) => setBankId(e.target.value)}
          /> */}
          <select
            value={bankId}
            onChange={(e) => {
              setBankId(e.target.value);
              handleUpdateCashItem(id, { bank_id: e.target.value }, {}, 0);
            }}
          >
            <option value="0">은행명</option>
            <option value="039">경남은행</option>
            <option value="034">광주은행</option>
            <option value="012">단위농협(지역농축협)</option>
            <option value="032">부산은행</option>
            <option value="045">새마을금고</option>
            <option value="064">산림조합</option>
            <option value="088">신한은행</option>
            <option value="048">신협</option>
            <option value="027">씨티은행</option>
            <option value="020">우리은행</option>
            <option value="071">우체국예금보험</option>
            <option value="050">저축은행중앙회</option>
            <option value="037">전북은행</option>
            <option value="035">제주은행</option>
            <option value="090">카카오뱅크</option>
            <option value="092">토스뱅크</option>
            <option value="081">하나은행</option>
            <option value="054">홍콩상하이은행</option>
            <option value="003">IBK기업은행</option>
            <option value="004">KB국민은행</option>
            <option value="031">DGB대구은행</option>
            <option value="002">KDB산업은행</option>
            <option value="011">NH농협은행</option>
            <option value="023">SC제일은행</option>
            <option value="007">Sh수협은행</option>
          </select>
          <input
            type="text"
            placeholder="예금 계좌번호를 입력 해주세요."
            value={bankAccount}
            onChange={(e) => {
              setBankAccount(e.target.value);
              handleUpdateCashItem(id, { bank_account: e.target.value }, {}, 0);
            }}
          />
        </div>
      </td>
      <td>
        <input
          type="text"
          placeholder="0"
          value={cashAmount}
          className="align_right pr-10"
          onChange={(e) => {
            setCashAmount(e.target.value);
            handleCashAmountChange(e);
          }}
        />
      </td>
      <td>
        <select
          value={cashCurrency}
          onChange={(e) => {
            setCashCurrency(e.target.value);
            handleUpdateCashItem(id, { cash_currency: e.target.value }, {}, 0);
          }}
        >
          <option value="0">천원</option>
          {/* <option value="1">USD</option>
          <option value="2">EURO</option>
          <option value="3">JPY</option>
          <option value="4">CNY</option>
          <option value="5">VND</option> */}
        </select>
      </td>
      <td>
        <span className="blue none">예상환전금액: </span>
        <input
          type="text"
          placeholder="0"
          value={currencyExchange}
          className="none"
          onChange={(e) => {
            setCurrencyExchange(e.target.value);
            handleUpdateCashItem(
              id,
              { currency_exchange: e.target.value },
              {},
              0
            );
          }}
        />
        {/* 원 */}
      </td>
      <td>
        <button className={`del`} onClick={() => deleteCashItem(id)}>
          -
        </button>
      </td>
    </tr>
  );
};

export default CashItem;
